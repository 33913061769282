<template>
    <div class="border border-gray-100 shadow-md mb-4 flex flex-col justify-between p-2">
        <div>
            <h2 class="text-xl font-medium">{{ name }}</h2>
            <div class="mb-2 flex flex-row justify-between" v-for="cancerRisk in cancerRisks">
                <p class="mr-4">{{cancerRisk.name}}</p>
                <p v-if="hasRisk(cancerRisk.key)">Yes</p>
                <p v-else>No</p>
            </div>
        </div>


        <div class="flex flex-row justify-between mt-6">
            <p class="font-medium">Overall risk level:</p>
            <p :class="['text-lg', {'text-red-500': riskLevels.HIGH === riskLevel, 'text-yellow-500': riskLevels.MED === riskLevel, 'text-green-500': riskLevels.LOW === riskLevel}]">{{riskLevelName}}</p>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import {CancerRisk, CancerRiskLevel, cancerRiskLevelName} from "@/Models";
export default defineComponent({
    name: 'CancerRiskCard',
    props: {
        name: String,
        patientRisks: {
            type: Object as PropType<string[]>,
            required: true
        },
        cancerRisks: {
            type: Object as PropType<CancerRisk[]>,
            required: true
        },
        riskLevel: {
            type: String as PropType<CancerRiskLevel>,
            required: true
        }
    },
    setup(props) {
        let hasRisk = (risk: string) => {
            return props.patientRisks.includes(risk)
        }

        let riskLevelName = computed(() => {
            return cancerRiskLevelName(props.riskLevel)
        })

        return {hasRisk, riskLevelName, riskLevels: CancerRiskLevel}
    }
})
</script>

<style scoped>

</style>