<template>
    <div class="flex flex-row justify-between">
        <div class="flex flex-row items-center">
            <input type="text" v-model="selectedPatient" placeholder="Enter a patient ID" class="mr-4">
            <button @click="fetchData" class="py-2 px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75">Look up</button>
        </div>
        <div class="flex flex-col items-center">
            <p class="text-lg">Cancer Risk Breakdown</p>
            <PieChart v-if="!loading" :chart-data="chartData"></PieChart>
            <div class="h-40" v-else></div>
        </div>

    </div>
    <div class="flex flex-row flex-wrap justify-between mt-8" v-if="!loading">
        <CancerRiskCard v-for="patientRisk in patientRisks"
                        :risk-level="patientRisk.riskLevel"
                        :cancer-risks="getRisksForCancer(patientRisk.cancerName)"
                        :patient-risks="patientRisk.risks"
                        :name="patientRisk.cancerName"
                        class="w-3/10 mr-4"></CancerRiskCard>
    </div>
    <div class="h-64" v-else>
        <p>Loading...</p>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from 'vue';
    import {CancerRisk, CancerRiskLevel, CancerRiskResults, cancerRisks} from "@/Models"; // @ is an alias to /src
    import axios from 'axios';
    import CancerRiskCard from "@/components/CancerRiskCard.vue";
    import {useRoute} from "vue-router";
    import PieChart from "@/components/PieChart.vue";

    export default defineComponent({
        name: 'Home',
        components: {
            CancerRiskCard,
            PieChart,
        },
        setup() {
            let selectedPatient = ref("")
            let loading = ref(false);
            const getCancerRiskFactors = async (): Promise<CancerRiskResults[]> => {
                try {
                    loading.value = true
                    const {data}: {data: CancerRiskResults[]} = await axios.get(process.env.VUE_APP_BACKEND_URL + `/risks/${selectedPatient.value}`)
                    loading.value = false
                    return data
                } catch (e) {
                    return [];
                }
            }

            const getRisksForCancer = (cancerName: string): CancerRisk[] => {
                if (cancerRisks.hasOwnProperty(cancerName)) {
                    return cancerRisks[cancerName]
                }

                return []
            }


            let patientRisks = ref<CancerRiskResults[]>([])

            const chartData = computed(() => {
                const lowCount = patientRisks.value.reduce((acc: number, currentValue: CancerRiskResults): number => {
                    if(currentValue.riskLevel === CancerRiskLevel.LOW) {
                        return acc + 1
                    }
                    return acc
                }, 0)
                const medCount = patientRisks.value.reduce((acc: number, currentValue: CancerRiskResults): number => {
                    if(currentValue.riskLevel === CancerRiskLevel.MED) {
                        return acc + 1
                    }
                    return acc
                }, 0)
                const highCount = patientRisks.value.reduce((acc: number, currentValue: CancerRiskResults): number => {
                    if(currentValue.riskLevel === CancerRiskLevel.HIGH) {
                        return acc + 1
                    }
                    return acc
                }, 0)
                return {
                    labels: ['Low', 'Medium', 'High'],
                    datasets: [
                        {
                            label: 'Cancer Data',
                            backgroundColor: ['rgb(16, 185, 129)', 'rgb(245, 158, 11)', 'rgb(239, 68, 68)'],
                            data: [lowCount, medCount, highCount]
                        }
                    ]
                }
            })

            let fetchData = async () => {
                patientRisks.value = await getCancerRiskFactors();
            }

            return {patientRisks, cancerRisks, getRisksForCancer, chartData, selectedPatient, loading, fetchData}
        },
    });
</script>