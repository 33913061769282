export interface CancerRiskResults {
    risks: string[];
    cancerName: string;
    riskLevel: CancerRiskLevel
}

export enum CancerRiskLevel {
    LOW = 'LOW',
    MED = 'MED',
    HIGH = 'HIGH'
}

export function cancerRiskLevelName(level: CancerRiskLevel) {
    if(level == CancerRiskLevel.LOW) {
        return 'Low'
    } else if (level === CancerRiskLevel.MED) {
        return 'Medium'
    } else {
        return 'High'
    }
}

export interface CancerRisk {
    name: string;
    key: string;
}

const cancerRisks = {
    'Breast Cancer': [
        {
            name: "Female",
            key: "Gender"
        },
        {
            name: "Over 55",
            key: "Age"
        },
        {
            name: "BRCA1/BRCA2 Gene Mutation",
            key: "BRCA"
        },
        {
            name: "Familial Breast Cancer History",
            key: "CancerHistory"
        },
        {
            name: "Familial Breast Cancer History - Female Relative",
            key: "CancerHistoryRelativeFemale"
        },
        {
            name: "White",
            key: "Race"
        },
        {
            name: "Atypical Ductal Hyperlasia",
            key: "AtypicalDuctalHyperplasia"
        },
        {
            name: "Atypical Lobular Hyperplasia",
            key: "AtypicalLobularHyperplasia"
        },
        {
            name: "BMI",
            key: "BMI"
        },
    ],
    'Colorectal Cancer': [
        {
            name: "Over 50",
            key: "Age"
        },
        {
            name: "Colorectal Polyps",
            key: "ColorectalPolyps"
        },
        {
            name: "Inflammatory Bowel Disorder",
            key: "IBD"
        },
        {
            name: "Familial Colorectal Cancer History",
            key: "CancerHistory"
        },
        {
            name: "Lynch Syndrome",
            key: "LynchSyndrome"
        },
        {
            name: "Puetz-Jeghers Syndrome",
            key: "JeghersSyndrome"
        },
        {
            name: "Familial Adenomatous Polyposis",
            key: "FamilialAP"
        },
        {
            name: "Diabetes",
            key: "Diabetes"
        },
        {
            name: "African American",
            key: "Race"
        },
    ],
    'Gallbladder Cancer': [
        {
            name: "Gallstones",
            key: "Gallstones"
        },
        {
            name: "Porcelain Gallbladder",
            key: "PorcelainGallbladder"
        },
        {
            name: "Female",
            key: "Gender"
        },
        {
            name: "Over 65",
            key: "Age"
        },
        {
            name: "Choledochal Cysts",
            key: "CholedochalCysts"
        },
        {
            name: "Gallblader Polyps",
            key: "GallbladderPolyps"
        },
        {
            name: "Primary Cholangitits",
            key: "PrimaryCholangitis"
        },
        {
            name: "Typhoid",
            key: "Typhoid"
        },
        {
            name: "American Indian or Alaskan Native",
            key: "Race"
        },
    ],
    'Kidney Cancer': [
        {
            name: "High Blood Pressure",
            key: "HighBloodPressure"
        },
        {
            name: "Familial Kidney Cancer History",
            key: "CancerHistory"
        },
        {
            name: "Male",
            key: "Gender"
        },
        {
            name: "African American",
            key: "Race"
        },
        {
            name: "Chronic Kidney Disease",
            key: "ChronicKidneyDisease"
        },
    ],
    'Liver Cancer': [
        {
            name: "Male",
            key: "Gender"
        },
        {
            name: "Pacific Islander",
            key: "Race"
        },
        {
            name: "Chronic Hepatitis",
            key: "ChronicHepatitis"
        },
        {
            name: "Cirrhosis",
            key: "Cirrhosis"
        },
        {
            name: "Hemochromatosis",
            key: "Hemochromatosis"
        },
        {
            name: "Moedrate to Heavy Alcohol Use",
            key: "AlcoholUse"
        },
        {
            name: "Smoking History",
            key: "SmokingHistory"
        },
        {
            name: "BMI",
            key: "BMI"
        },
        {
            name: "Diabetes",
            key: "Diabetes"
        },
    ],
    'Lung Cancer': [
        {
            name: "Smoking History",
            key: "SmokingHistory"
        },
        {
            name: "Familial Lung Cancer History",
            key: "CancerHistory"
        },
    ],
    'Ovarian Cancer': [
        {
            name: "Over 40",
            key: "Age"
        },
        {
            name: "BMI",
            key: "BMI"
        },
        {
            name: "Familial Ovarian History Cancer",
            key: "CancerHistory"
        },
        {
            name: "Hereditary Cancer Syndrome",
            key: "HereditaryCancerSyndrome"
        },
        {
            name: "Lynch Syndrome",
            key: "LynchSyndrome"
        },
        {
            name: "Puetz-Jeghers Syndrome",
            key: "JeghersSyndrome"
        },
        {
            name: "Smoking History",
            key: "SmokingHistory"
        },
    ],
    'Pancreatic Cancer': [
        {
            name: "BMI",
            key: "BMI"
        },
        {
            name: "Smoking History",
            key: "SmokingHistory"
        },
        {
            name: "Moderate to Heavy Alcohol Use",
            key: "AlcoholUse"
        },
        {
            name: "Diabetes",
            key: "Diabetes"
        },
        {
            name: "Pancreatitis",
            key: "Pancreatitis"
        },
        {
            name: "Over 45",
            key: "Age"
        },
        {
            name: "Male",
            key: "Gender"
        },
        {
            name: "BRCA1/BRCA2 Gene Mutation",
            key: "BRCA"
        },
        {
            name: "Lynch Syndrome",
            key: "LynchSyndrome"
        },
        {
            name: "Puetz-Jeghers Syndrome",
            key: "JeghersSyndrome"
        },
        {
            name: "Familial Pancreatitis",
            key: "FamilialPancreatitis"
        },
        {
            name: "Atypical Mole Syndrome",
            key: "MoleMelanoma"
        },
    ],
    'Prostate Cancer': [
        {
            name: "Over 50",
            key: "Age"
        },
        {
            name: "African American",
            key: "Race"
        },
        {
            name: "Familial Prostate Cancer History",
            key: "CancerHistory"
        },
        {
            name: "Lynch Syndrome",
            key: "LynchSyndrome"
        },
        {
            name: "BRCA1/BRCA2 Gene Mutation",
            key: "BRCA"
        },
    ],
    'Stomach Cancer': [
        {
            name: "Male",
            key: "Gender"
        },
        {
            name: "Over 50",
            key: "Age"
        },
        {
            name: "African American or American Indian",
            key: "Race"
        },
        {
            name: "Helicobacter Infection",
            key: "HelicobacterInfection"
        },
        {
            name: "Stomach Lymphoma",
            key: "StomachLymphoma"
        },
        {
            name: "Pernicious Anemia",
            key: "PerniciousAnemia"
        },
        {
            name: "Menetrier Disease",
            key: "MenetrierDisease"
        },
        {
            name: "Hereditary Gastric Cancer",
            key: "HereditaryGastricCancer"
        },
        {
            name: "Lynch Syndrome",
            key: "LynchSyndrome"
        },
        {
            name: "Familial Adenomatous Polyposis",
            key: "FamilialAP"
        },
        {
            name: "BRCA1/BRCA2 Gene Mutation",
            key: "BRCA"
        },
        {
            name: "Li-Fraumeni Syndrome",
            key: "LiFraumeni"
        },
        {
            name: "Puetz-Jeghers Syndrome",
            key: "JeghersSyndrome"
        },
        {
            name: "Familial Stomach Cancer History",
            key: "CancerHistory"
        },
        {
            name: "Epstein-Barr Virus",
            key: "EpsteinBarr"
        },
        {
            name: "Chronic Viral Inflammatory Disease",
            key: "CVID"
        },
    ],
    'Thyroid Cancer': [
        {
            name: "Female",
            key: "Gender"
        },
        {
            name: "Medlary Thyroid Cancer",
            key: "MedullaryThyroidCancer"
        },
        {
            name: "Familial Adenomatous Polyposis",
            key: "FamilialAP"
        },
        {
            name: "Cowden Disease",
            key: "Cowden"
        },
        {
            name: "Carney Complex",
            key: "CarneyComplex"
        },
        {
            name: "Familial Thyroid Carcinoma",
            key: "FamilialThyroidCarcinoma"
        },
        {
            name: "BMI",
            key: "BMI"
        },
    ]
} as {[key: string]: CancerRisk[]}

export {cancerRisks}