
  import {computed, defineComponent, onMounted, ref, watch} from 'vue';
  import {CancerRisk, CancerRiskLevel, CancerRiskResults, cancerRisks} from "@/Models"; // @ is an alias to /src
  import axios from 'axios';
  import CancerRiskCard from "@/components/CancerRiskCard.vue";
  import {useRoute} from "vue-router";
  import PieChart from "@/components/PieChart.vue";

  export default defineComponent({
  name: 'Home',
  components: {
    CancerRiskCard,
    PieChart,
  },
  setup() {
    let selectedPatient = ref("1")
    let loading = ref(false);
    const getCancerRiskFactors = async (patientId: string): Promise<CancerRiskResults[]> => {
      try {
        loading.value = true
        const {data}: {data: CancerRiskResults[]} = await axios.get(process.env.VUE_APP_BACKEND_URL + `/risks/${patientId}`)
        loading.value = false
        return data
      } catch (e) {
        return [];
      }
    }

    const getRisksForCancer = (cancerName: string): CancerRisk[] => {
      if (cancerRisks.hasOwnProperty(cancerName)) {
        return cancerRisks[cancerName]
      }

      return []
    }


    let patientRisks = ref<CancerRiskResults[]>([])

    const chartData = computed(() => {
      const lowCount = patientRisks.value.reduce((acc: number, currentValue: CancerRiskResults): number => {
        if(currentValue.riskLevel === CancerRiskLevel.LOW) {
          return acc + 1
        }
        return acc
      }, 0)
      const medCount = patientRisks.value.reduce((acc: number, currentValue: CancerRiskResults): number => {
        if(currentValue.riskLevel === CancerRiskLevel.MED) {
          return acc + 1
        }
        return acc
      }, 0)
      const highCount = patientRisks.value.reduce((acc: number, currentValue: CancerRiskResults): number => {
        if(currentValue.riskLevel === CancerRiskLevel.HIGH) {
          return acc + 1
        }
        return acc
      }, 0)
      return {
        labels: ['Low', 'Medium', 'High'],
        datasets: [
          {
            label: 'Cancer Data',
            backgroundColor: ['rgb(16, 185, 129)', 'rgb(245, 158, 11)', 'rgb(239, 68, 68)'],
            data: [lowCount, medCount, highCount]
          }
        ]
      }
    })

    watch(selectedPatient, async (newVal, oldVal) => {
      patientRisks.value = await getCancerRiskFactors(selectedPatient.value)
    })
    onMounted(async () => {
      patientRisks.value = await getCancerRiskFactors(selectedPatient.value);
    })

    return {patientRisks, cancerRisks, getRisksForCancer, chartData, selectedPatient, loading}
  },
});
