<template>
  <div class="flex flex-row">
    <div class="w-1/5 mr-4 pt-8">
      <div :class="['border border-gray-200 cursor-pointer', {'bg-blue-600 text-white': $route.name === 'Home'}]">
        <p @click="this.$router.push('/')">Example patients</p>
      </div>

      <div :class="['border border-gray-200 cursor-pointer', {'bg-blue-600 text-white': $route.name === 'PatientLookup'}]">
        <p @click="this.$router.push({name: 'PatientLookup'})">Look up a patient</p>
      </div>

      <div :class="['border border-gray-200 cursor-pointer', {'bg-blue-600 text-white': $route.name === 'About'}]">
        <p @click="this.$router.push('/about')">About</p>
      </div>
    </div>

    <div class="w-4/5">
      <router-view/>
    </div>
  </div>


</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
