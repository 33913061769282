
    //@ts-ignore
    import {Pie, mixins} from '@/lib/v-chart-js/index.js'
    export default {
        name: "PieChart",
        extends: Pie,
        mixins: [mixins.reactiveProp],
        mounted() {
            // this.chartData is created in the mixin.
            // If you want to pass options please create a local options object
            //@ts-ignore
            this.renderChart(this.chartData, {
                responsive: false
            })
        },
    }
