
import { defineComponent, PropType, computed } from 'vue';
import {CancerRisk, CancerRiskLevel, cancerRiskLevelName} from "@/Models";
export default defineComponent({
    name: 'CancerRiskCard',
    props: {
        name: String,
        patientRisks: {
            type: Object as PropType<string[]>,
            required: true
        },
        cancerRisks: {
            type: Object as PropType<CancerRisk[]>,
            required: true
        },
        riskLevel: {
            type: String as PropType<CancerRiskLevel>,
            required: true
        }
    },
    setup(props) {
        let hasRisk = (risk: string) => {
            return props.patientRisks.includes(risk)
        }

        let riskLevelName = computed(() => {
            return cancerRiskLevelName(props.riskLevel)
        })

        return {hasRisk, riskLevelName, riskLevels: CancerRiskLevel}
    }
})
